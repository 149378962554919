<template>
  <div
    class="white full-width border-radius elevation"
    :class="[items.length > 0 ? '' : 'h-585']"
  >
    <DataTable
      :headers="headers"
      :items="items"
      :page="1"
      flat
      :disablePagination="true"
      class="application-table"
      :footer="false"
    >
      <template v-slot:item.title="{ item }">
        <div class="d-flex algn-center justify-left" @click.stop='$router.push({name : "InternshipPostEdit", params: {id: item.internship_post.id} })'>
          <v-sheet
            :elevation="0"
            width="260"
            height="20"
            color="transparent"
            class="text-dark-blue font-14px fw-400 text-truncate mouse-pointer"
            >{{ item.internship_post.title }}</v-sheet
          >
        </div>
      </template>
      <template v-slot:item.company_name="{ item }">
        <div class="text-dark-blue font-14px fw-400 mouse-pointer" @click.stop='$router.push({name : "CorporateEdit", params: {id: item.company.id} })'>
          {{ item.company.name }}
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        <div class="font-14px fw-400">{{ dateFormat(item.created_at, 'YYYY/MM/DD') }}</div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          small
          pill
          v-if="item.status === 1"
          color="#EE6C9B"
          class="font-12px white--text"
          ><div class="px-4">応募済</div></v-chip
        >
        <v-chip
          small
          pill
          v-if="item.status === 2"
          color="#5E94E4"
          class="font-12px white--text"
          ><div class="px-4">合格済</div></v-chip
        >
        <v-chip
          small
          pill
          v-if="item.status === 3"
          color="#60D1CB"
          class="font-12px white--text"
          ><div class="px-4">完了</div></v-chip
        >
        <v-chip
          small
          pill
          v-if="item.status === 4"
          color="#A7A7A7"
          class="font-12px white--text"
          ><div class="px-4">不合格
</div></v-chip
        >
        <v-chip
          small
          pill
          v-if="item.status === 5"
          color="#C771B5"
          class="font-12px white--text"
          ><div class="px-4">辞退済</div></v-chip
        >
      </template>
    
    </DataTable>
  </div>
</template>
<script>
import DataTable from '@/components/ui/DataTable'
export default {
  components: {
    DataTable
  },
  data() {
    return {
      headers: [
        {
          text: '求人タイトル',
          align: 'left',
          value: 'title'
        },
        {
          text: '企業名',
          value: 'company_name',
          align: 'left',
        },
        {
          text: '応募日 ',
          align: 'left',
          value: 'created_at',
        },
        {
          text: 'ステータス',
          align: 'left',
          value: 'status'
        }
      ],
      items: []
    }
  },
  created () {
    this.getApplication()
  },
  methods: {
    async getApplication() {

      this.loading = true

      let data = {}
      data.sort_by = 'created_at'
      let response = await this.$store.dispatch('APPLICATION_STUDENT_GET', { id: this.$route.params.id })
      response.data.data.data.forEach(item => {
        this.items.push(item)
      })
      this.total = response.data.total
      this.loading = false
    }
  },
}
</script>
<style src="./index.scss" lang="scss"></style>
