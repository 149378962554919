var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white full-width border-radius elevation",class:[_vm.items.length > 0 ? '' : 'h-585']},[_c('DataTable',{staticClass:"application-table",attrs:{"headers":_vm.headers,"items":_vm.items,"page":1,"flat":"","disablePagination":true,"footer":false},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex algn-center justify-left",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({name : "InternshipPostEdit", params: {id: item.internship_post.id} })}}},[_c('v-sheet',{staticClass:"text-dark-blue font-14px fw-400 text-truncate mouse-pointer",attrs:{"elevation":0,"width":"260","height":"20","color":"transparent"}},[_vm._v(_vm._s(item.internship_post.title))])],1)]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-dark-blue font-14px fw-400 mouse-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({name : "CorporateEdit", params: {id: item.company.id} })}}},[_vm._v(" "+_vm._s(item.company.name)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-14px fw-400"},[_vm._v(_vm._s(_vm.dateFormat(item.created_at, 'YYYY/MM/DD')))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 1)?_c('v-chip',{staticClass:"font-12px white--text",attrs:{"small":"","pill":"","color":"#EE6C9B"}},[_c('div',{staticClass:"px-4"},[_vm._v("応募済")])]):_vm._e(),(item.status === 2)?_c('v-chip',{staticClass:"font-12px white--text",attrs:{"small":"","pill":"","color":"#5E94E4"}},[_c('div',{staticClass:"px-4"},[_vm._v("合格済")])]):_vm._e(),(item.status === 3)?_c('v-chip',{staticClass:"font-12px white--text",attrs:{"small":"","pill":"","color":"#60D1CB"}},[_c('div',{staticClass:"px-4"},[_vm._v("完了")])]):_vm._e(),(item.status === 4)?_c('v-chip',{staticClass:"font-12px white--text",attrs:{"small":"","pill":"","color":"#A7A7A7"}},[_c('div',{staticClass:"px-4"},[_vm._v("不合格 ")])]):_vm._e(),(item.status === 5)?_c('v-chip',{staticClass:"font-12px white--text",attrs:{"small":"","pill":"","color":"#C771B5"}},[_c('div',{staticClass:"px-4"},[_vm._v("辞退済")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }