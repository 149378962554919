<template>
  <div class="student-profile">
    <!-- page NavBar  -->
    <PageTitle :items="navigation"></PageTitle>
    <div
      v-if="showUpdateSuccessAlert"
      class="d-flex justify-center"
      style="z-index: 1000; position: fixed; width: 100%; top: 90px"
    >
      <v-alert class="w-25" type="success" color="#13aba3"
        >基本情報を更新しました。
      </v-alert>
    </div>
    <!-- main section starts here -->
    <div class="d-flex align-start justify-start full-width">
      <div
        class="
          d-flex
          flex-column
          align-center
          justify-center
          sidebar
          white
          border-radius
          elevation
        "
      >
        <!-- basic information -->
        <v-sheet
          width="229px"
          :elevation="0"
          class="fw-400 mx-4 mb-82 text-333"
        >
          <div class="font-14px">
            <span class="mr-2">ID</span>
            <span>{{ basicInfo.id }}</span>
          </div>
          <div class="font-20px mt-2 mb-1">{{ basicInfo.name }}</div>
          <div class="font-18px">{{ basicInfo.name_furigana }}</div>
          <div class="font-14px text-8e8e mt-5">
            登録日：{{ basicInfo.created_at }}
          </div>
        </v-sheet>
        <!-- profile menu -->
        <v-btn
          width="229px"
          height="43px"
          depressed
          class="mx-4 mb-4 text-left"
          v-for="(item, index) in options"
          :color="item === menuModel ? '#D5FAF8' : 'transparent'"
          @click="updateMenu(index)"
          :key="index"
        >
          <div class="full-width">{{ item }}</div>
        </v-btn>
      </div>
      <!-- form -->
      <StudentForm
        v-if="menuModel === options[0]"
        class="ml-4"
        :form="form"
        @updateStudentDetails="updateStudentDetails"
      ></StudentForm>
      <ApplicationTable v-if="menuModel === options[1]" class="ml-4">
      </ApplicationTable>
      <Report v-if="menuModel === options[2]" class="ml-4"></Report>
    </div>
    <SuccessModel
      :text="alertText"
      :buttonText="`一覧へ戻る`"
      :routeName="routeName"
      :dialog="successDialog"
      @closeModel="successDialog = false"
    >
    </SuccessModel>
  </div>
</template>
<script>
import SuccessModel from '@/components/models/SuccessModel'
import StudentForm from './StudentForm.vue'
import moment from 'moment'
import ApplicationTable from './ApplicationTable.vue'
import Report from './report.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    StudentForm,
    ApplicationTable,
    Report,
    SuccessModel
  },
  data() {
    return {
      dialog: {
        submit: false
      },
      navigation: null,
      options: ['基本情報', '応募インターン', 'フィードバック'],
      menuModel: '基本情報',
      basicInfo: {
        id: '',
        name: '',
        name_furigana: '',
        created_at: ''
      },
      send_email: '',
      form: {
        family_name: '',
        first_name: '',
        family_name_furigana: '',
        first_name_furigana: '',
        email_valid: '',
        education_facility_id: '',
        year: 0,
        month: 0,
        self_introduction: '',
        status: '',
        withdrawal_reason: ''
      },
      showUpdateSuccessAlert: false,
      alertText: '',
      successDialog: false,
      routeName: '',
      studentId: null
    }
  },
  created() {
    this.navigation = {
      title: '学生',
      subTitle: '学生詳細',
      back: {
        action: () => {
          this.$router.back(-1)
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getSingleStudent'])
  },
  async mounted() {
    if (this.$route.params.id) {
      this.studentId = this.$route.params.id
      await this.$store
        .dispatch('GET_SINGLE_STUDENT', this.studentId)
        .catch(() => {
          this.$router.push({
            name: 'Students'
          })
        })
      this.getStudentDetails()
      if (this.form.is_admin_read === 0) {
        this.form.is_admin_read = 1
        await this.updateStudentDetails(false)
        await this.$store.dispatch('GET_MASTER_DATA', false)
      }
    }
  },
  methods: {
    getStudentDetails() {
      this.form.student_internal_id = this.getSingleStudent.student_internal_id
      this.form.created_at = this.getSingleStudent.created_at
      this.form.family_name = this.getSingleStudent.family_name
      this.form.first_name = this.getSingleStudent.first_name
      this.form.family_name_furigana = this.getSingleStudent.family_name_furigana
      this.form.first_name_furigana = this.getSingleStudent.first_name_furigana
      this.form.email_valid = this.getSingleStudent.email_valid
      this.form.education_facility_id = this.getSingleStudent.education_facility_id
      this.form.year = this.getSingleStudent.graduate_year
      this.form.month = parseInt(this.getSingleStudent.graduate_month)
      this.form.self_introduction = this.getSingleStudent.self_introduction
      this.form.status = this.getSingleStudent.status
      this.form.is_admin_read = this.getSingleStudent.is_admin_read
      this.form.withdrawal_reason = this.getSingleStudent.reason_for_withdrawal
      this.form.student_id = this.studentId
      this.getInfo(this.form)
    },
    async updateStudentDetails(alert, showLoader = true) {
      await this.$store.dispatch('UPDATE_SINGLE_STUDENT', {
        studentId: this.studentId,
        form: this.form,
        showLoader
      })
      this.send_email = true
      if (alert) {
        this.alertText = `学生情報を更新しました。`
        this.routeName = `Students`
        this.successDialog = true
      }
    },
    updateMenu(index) {
      this.menuModel = this.options[index]
    },

    getInfo(value) {
      this.basicInfo.id = value.student_internal_id
      this.basicInfo.name = value.family_name + ' ' + value.first_name
      this.basicInfo.name_furigana =
        value.family_name_furigana + ' ' + value.first_name_furigana
      this.basicInfo.created_at = moment(String(value.created_at)).format(
        'YYYY/MM/DD'
      )
    }
  }
}
</script>
<style src="./index.scss" lang="scss"></style>
